import { ART_STORE } from '../../constants';

export default class fedopsLogger {
  constructor(context) {
    const { platformServices } = context.getContext();
    const fedOpsLoggerFactory = platformServices.fedOpsLoggerFactory;
    this.loggerFactory = fedOpsLoggerFactory;
    this.logger = this.getLogger();
    this.isAppLoadedReported = false;
    this.isAppLoadStartReported = false;
  }

  getLogger() {
    let logger;
    try {
      logger = this.loggerFactory.getLoggerForWidget({
        appId: ART_STORE.ARTSTORE_APP_DEFINITION_ID,
        widgetId: ART_STORE.THANK_YOU_WIDGET_ID,
      });
    } catch (e) {
      console.error('Cannot create fedops logger', e);
      logger = {
        appLoaded: () => {},
        appLoadStarted: () => {},
      };
    }
    return logger;
  }

  reportAppLoadStarted() {
    this.logger.appLoadStarted();
    this.isAppLoadStartReported = true;
  }

  tryReportAppLoaded() {
    if (this.isAppLoadStartReported && !this.isAppLoadedReported) {
      try {
        this.logger.appLoaded();
        this.isAppLoadedReported = true;
      } catch (e) {
        console.error('Cannot report thank-you widget appLoaded ', e);
      }
    }
  }
}
